<template>
  <div>
    <div style="display: flex;margin-bottom: 5px;">
      <a-page-header
        style="border: 0px solid rgb(235, 237, 240); margin: 0px; padding: 10px;display: inline"
        :title="this.name"
        @back="() => $router.go(-1)"
      >
        <a-row v-if="true">
          <div v-if="isCreate()" style="margin-top: 90px;">
            <a-button type="primary" icon="plus" @click="handleAdd" v-action:add>新建</a-button>
          </div>
        </a-row>
      </a-page-header>
      <div style="padding-left:80px; margin-left: 80px; width: 60%;height: 100%; text-align: right">
        <table border="0" cellspacing="0" cellpadding="0" v-if="statistics && this.dataSource">
          <tr>
            <th rowspan="2">类别</th>
            <th colspan="3" style="width: 300px;">南区</th>
            <th colspan="3" style="width: 300px;">北区</th>
            <th rowspan="2">总计</th>
          </tr>
          <tr>
            <td>自营</td>
            <td>管委会</td>
            <td>济高</td>
            <td>自营</td>
            <td>管委会</td>
            <td>济高</td>
          </tr>
          <tr>
            <td rowspan="1">付款、报销</td>
            <td class="text_right" :class="{ active: this.statistics.pay.south_self > this.statistics.fee.south_self }">{{ (this.statistics.pay.south_self * 0.01).toFixed(2) | numberFormat }}</td>
            <td class="text_right" :class="{ active: this.statistics.pay.south_gov > this.statistics.fee.south_gov }">{{ (this.statistics.pay.south_gov * 0.01).toFixed(2) | numberFormat }}</td>
            <td class="text_right" :class="{ active: this.statistics.pay.south_jg > this.statistics.fee.south_jg }">{{ (this.statistics.pay.south_jg * 0.01).toFixed(2) | numberFormat }}</td>
            <td class="text_right" :class="{ active: this.statistics.pay.north_self > this.statistics.fee.north_self }">{{ (this.statistics.pay.north_self * 0.01).toFixed(2) | numberFormat }}</td>
            <td class="text_right" :class="{ active: this.statistics.pay.north_gov > this.statistics.fee.north_gov }">{{ (this.statistics.pay.north_gov * 0.01).toFixed(2) | numberFormat }}</td>
            <td class="text_right" :class="{ active: this.statistics.pay.north_jg > this.statistics.fee.north_jg }">{{ (this.statistics.pay.north_jg * 0.01).toFixed(2) | numberFormat }}</td>
            <td class="text_right" :class="{ active: (this.statistics.pay.south_self + this.statistics.pay.south_gov+ this.statistics.pay.south_jg + this.statistics.pay.north_self+this.statistics.pay.north_gov+ this.statistics.pay.north_jg) > (this.statistics.fee.south_self + this.statistics.fee.south_gov+ this.statistics.fee.south_jg + this.statistics.fee.north_self+this.statistics.fee.north_gov+ this.statistics.fee.north_jg)}">{{ ((this.statistics.pay.south_self + this.statistics.pay.south_gov+ this.statistics.pay.south_jg + this.statistics.pay.north_self+this.statistics.pay.north_gov+ this.statistics.pay.north_jg) * 0.01).toFixed(2) | numberFormat }}</td>
          </tr>
          <tr>
            <td rowspan="1">报销、冲销</td>
            <td class="text_right" :class="{ active: this.statistics.pay.south_self < this.statistics.fee.south_self }">{{ (this.statistics.fee.south_self * 0.01).toFixed(2) | numberFormat }}</td>
            <td class="text_right" :class="{ active: this.statistics.pay.south_gov < this.statistics.fee.south_gov }">{{ (this.statistics.fee.south_gov * 0.01).toFixed(2) | numberFormat }}</td>
            <td class="text_right" :class="{ active: this.statistics.pay.south_jg < this.statistics.fee.south_jg }">{{ (this.statistics.fee.south_jg * 0.01).toFixed(2) | numberFormat }}</td>
            <td class="text_right" :class="{ active: this.statistics.pay.north_self < this.statistics.fee.north_self }">{{ (this.statistics.fee.north_self * 0.01).toFixed(2) | numberFormat }}</td>
            <td class="text_right" :class="{ active: this.statistics.pay.north_gov < this.statistics.fee.north_gov }">{{ (this.statistics.fee.north_gov * 0.01).toFixed(2) | numberFormat }}</td>
            <td class="text_right" :class="{ active: this.statistics.pay.north_jg< this.statistics.fee.north_jg }">{{ (this.statistics.fee.north_jg * 0.01).toFixed(2) | numberFormat }}</td>
            <td class="text_right" :class="{ active: (this.statistics.pay.south_self + this.statistics.pay.south_gov+ this.statistics.pay.south_jg + this.statistics.pay.north_self+this.statistics.pay.north_gov+ this.statistics.pay.north_jg) < (this.statistics.fee.south_self + this.statistics.fee.south_gov+ this.statistics.fee.south_jg + this.statistics.fee.north_self+this.statistics.fee.north_gov+ this.statistics.fee.north_jg) }">{{ ((this.statistics.fee.south_self + this.statistics.fee.south_gov+ this.statistics.fee.south_jg + this.statistics.fee.north_self+this.statistics.fee.north_gov+ this.statistics.fee.north_jg) * 0.01).toFixed(2) | numberFormat }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="table-page-search-wrapper" v-if="false">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="4" :sm="24">
            <a-form-item label="名称">
              <a-input v-model="queryParam.name" placeholder="请输入" allowClear />
            </a-form-item>
          </a-col>
          <a-col :md="8" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <a-spin :spinning="spinning">
      <a-table
        v-if="dataSource && dataSource.length"
        ref="table"
        size="small"
        rowKey="id"
        :loading="loading"
        :columns="columns"
        :data-source="dataSource"
        :pagination="false"
        :expandIconAsCell="false"
        :expandIconColumnIndex="-1"
        :indentSize="10"
        :showPagination="false"
        :defaultExpandAllRows="defaultExpandAllRows"
      >
        <span slot="serial" slot-scope="text, record">
          <child-icon :style="{ display: 'inline' }" v-if="record.item_id !==-1 " />
          {{ text }}
        </span>
        <div slot="money_render" slot-scope="text, record" style="text-align: right; min-width: 90px; max-width: 100px">
          {{ record.category === 1 ? '-' : (text / 100).toFixed(2) | numberFormat }}
        </div>
        <span slot="imgs" slot-scope="image_urls">
          <a>
            <a-avatar
              v-if="image_urls.length > 0"
              slot="image_urls"
              :src="image_urls[0].img"
              shape="square"
              @click="showAllImages(image_urls)"
            />
          </a>
          <a v-if="image_urls.length > 1" @click="showAllImages(image_urls)">...</a>
        </span>
        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleEdit(record)" v-if="record.item_id===-1 && isCreate()" v-action:add>新建</a>
            <a-divider type="vertical" v-if="record.item_id===-1 && isCreate()" />
            <a
              v-action:edit
              @click="handleStatus(record)"
              v-if="record.status !== 3"
              :class="{'a_disable':record.dingding_ope ===1}"
              :href="record.dingding_ope ===1? 'javascript:;': ''">
              {{ showOpeStatus }}
            </a>
            <a-divider type="vertical" v-if="record.status !== 3" />
            <a
              v-action:import
              @click="createPayVoucher(record)"
              v-if="record.status === 3 && (record.category === 2 || record.category === 3 || record.category === 4) && !record.is_voucher">
              创建凭证
            </a>
            <a-divider
              v-action:import
              v-if="record.status === 3 && (record.category ===2 || record.category ===3) && !record.is_voucher"
              type="vertical" />
            <a @click="watchList(record)">查看</a>
            <a-divider type="vertical" v-if="isCreate() && record.status !==3" />
            <a-popconfirm
              v-action:delete
              v-if="isCreate() && record.status !==3"
              title="确定要删除么？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="handleDelete(record)"
            >
              <a>删除</a>
            </a-popconfirm>
          </template>
        </span>
      </a-table>
    </a-spin>
    <create-form
      v-if="visible"
      ref="createModal"
      :visible="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
    <a-drawer
      title="列表"
      placement="right"
      :closable="false"
      :visible="visibleDrawer"
      @close="onClose"
      width="500"
    >
      <a-table
        :columns="drawer_columns"
        :data-source="corporations"
        :pagination="false"
      >
        <span slot="serial" slot-scope="text, record, index">{{ index + 1 }}
        </span>
        <div slot="file" slot-scope="text">
          <a :href="text" target="_blank">{{ text }}</a>
        </div>
      </a-table>
    </a-drawer>
    <a-drawer
      title="列表"
      placement="right"
      :closable="true"
      :visible="visibleDrawer2"
      @close="() => this.visibleDrawer2 = false"
      width="1200"
    >
      <a-table
        bordered
        :columns="drawer_columns2"
        :data-source="corporations"
        :pagination="false"
      >
        <span slot="serial" slot-scope="text, record, index">{{ index + 1 }}
        </span>
        <div slot="file" slot-scope="text">
          <a :href="text" target="_blank">{{ text }}</a>
        </div>
        <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 90px; max-width: 100px">
          {{ (text / 100).toFixed(2) | numberFormat }}
        </div>
      </a-table>
    </a-drawer>
    <preview
      ref="preview"
      :visible="previsible"
      :loading="preLoading"
      :content="content"
      @cancel="handlepreCancel"
      @ok="handlepreOk"
    />

    <a-modal v-model="previewvisible" title="预览" width="350px" :footer="null">
      <img alt="example" style="width: 300px; margin: 0 auto" :src="previewurl" />
    </a-modal>
  </div>
</template>

<script>
import { STable } from '@/components'
import { Dictionaries } from '@/common/AllConstant'
import CreateForm from '../modules/CreateForm'
import EditImagesForm from '../modules/AddItemForm'
import ChildIcon from '../modules/ChildIcon'
import Preview from '../modules/preview'
import {
  property_project_item_list,
  property_project_item_create,
  property_project_item_delete,
  property_project_item_update,
  property_project_item_payable,
  property_project_item_pay_voucher
} from '@/api/property_project_item'

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows)
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows)
  }
}

export default {
  name: 'TableList',
  components: {
    EditImagesForm,
    STable,
    CreateForm,
    Preview,
    ChildIcon
  },
  data () {
    return {
      rowSelection,
      expandedRowKeys: [],
      spinning: false,
      property_bill_visible: false,
      rent_bill_visible: false,
      previewvisible: false,
      previewurl: '',
      is_open_dingding: 0,
      parent_status: 1,
      subject: 6,
      name: '',
      visibleDrawer: false,
      visibleDrawer2: false,
      statistics: {
        pay: {
          north_gov: 0,
          north_jg: 0,
          north_self: 0,
          south_gov: 0,
          south_jg: 0,
          south_self: 0
        },
        fee: {
          north_gov: 0,
          north_jg: 0,
          north_self: 0,
          south_gov: 0,
          south_jg: 0,
          south_self: 0
        }
      },
      dataSource: [],
      corporations: [],
      corporation_id: null,
      tip: '',
      corList: [],
      bookLoading: false,
      billLoading: false,
      billTime: {},
      content: '',
      previsible: false,
      loading: false,
      defaultExpandAllRows: true,
      confirmLoading: false,
      confirmPropertyBillLoading: false,
      confirmRentBillLoading: false,
      confirmAttachmentLoading: false,
      preLoading: false,
      dictionaries: Dictionaries,
      visibleAttachment: false,
      attachmentListItemId: 0,
      // 创建窗口控制
      confirmImagesLoading: false,
      visible: false,
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      drawer_columns: [
        {
          title: '序号',
          scopedSlots: { customRender: 'serial' },
          align: 'center',
          width: 80
        },
        {
          title: '名称',
          dataIndex: 'name',
          ellipsis: true
        },
        {
          title: '数量',
          dataIndex: 'count'
        },
        {
          title: '文件',
          dataIndex: 'f',
          scopedSlots: { customRender: 'file' }
        }
      ],
      drawer_columns2: [
        {
          title: '序号',
          scopedSlots: { customRender: 'serial' },
          align: 'center',
          width: 80
        },
        {
          title: '费用',
          dataIndex: 'fee',
          scopedSlots: { customRender: 'money_render' },
          align: 'center',
          ellipsis: true
        },
        {
          title: '税率',
          dataIndex: 'tax_rate',
          align: 'center',
          customRender: (text) => text + '%'
        },
        {
          title: '北区',
          children: [
            {
              title: '自营',
              dataIndex: 'north_self',
              scopedSlots: { customRender: 'money_render' },
              align: 'center',
              key: 'north_self'
            },
            {
              title: '管委会',
              dataIndex: 'north_gov',
              scopedSlots: { customRender: 'money_render' },
              align: 'center',
              key: 'north_gov'
            },
            {
              title: '济高',
              dataIndex: 'north_jg',
              scopedSlots: { customRender: 'money_render' },
              align: 'center',
              key: 'north_jg'
            }
          ]
        },
        {
          title: '南区',
          children: [
            {
              title: '自营',
              dataIndex: 'south_self',
              scopedSlots: { customRender: 'money_render' },
              align: 'center',
              key: 'south_self'
            },
            {
              title: '管委会',
              dataIndex: 'south_gov',
              scopedSlots: { customRender: 'money_render' },
              align: 'center',
              key: 'south_gov'
            },
            {
              title: '济高',
              dataIndex: 'south_jg',
              scopedSlots: { customRender: 'money_render' },
              align: 'center',
              key: 'south_jg'
            }
          ]
        },
        {
          title: '日期',
          dataIndex: 'year_month',
          customRender: (text) => text !== undefined ? text.substr(0, text.length - 3) : '-'
        }
      ],
      // 表头
      columns: [
        {
          title: '序号',
          dataIndex: 'index',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '名称',
          dataIndex: 'name',
          ellipsis: true
        },
        {
          title: '类别',
          dataIndex: 'category',
          customRender: (text) => this.$Dictionaries.property_project_category[text] || '-',
          align: 'center'
        },
        {
          title: '付款方式',
          dataIndex: 'payment.method',
          customRender: (text) => this.$Dictionaries.property_project_fee_method[text] || '-',
          align: 'center'
        },
        {
          title: '收款类型',
          dataIndex: 'payment.category',
          customRender: (text) => this.$Dictionaries.property_project_fee_category[text] || '-',
          align: 'center'
        },
        {
          title: '收款方',
          dataIndex: 'payment.name',
          customRender: (text) => text || '-',
          align: 'center'
        },
        {
          title: '总计',
          dataIndex: 'fee',
          scopedSlots: { customRender: 'money_render' },
          align: 'center'
        },
        {
          title: '科目',
          dataIndex: 'subject',
          width: 160,
          customRender: (text, record) => record.category === 1 ? '-' : this.$Dictionaries.property_project_fee_subject[text] || ''
        },
        {
          title: '说明',
          dataIndex: 'content',
          ellipsis: true
        },
        {
          title: '用章类型',
          dataIndex: 'seal.seal_type',
          customRender: (text, record) => record.category === 2 ? '-' : (text),
          ellipsis: true,
          align: 'center'
        },
        {
          title: '风控审核',
          dataIndex: 'seal.is_risk',
          customRender: (text, record) => record.category >= 2 ? '-' : (text ? '是' : '否'),
          align: 'center'
        },
        {
          title: '法务审核',
          dataIndex: 'seal.is_law',
          customRender: (text, record) => record.category >= 2 ? '-' : text ? '是' : '否',
          align: 'center'
        },
        {
          title: '状态',
          dataIndex: 'status',
          customRender: (text) => this.$Dictionaries.property_project_check_status[text] || '',
          align: 'center'
        },
        {
          width: 250,
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  created () {
    this.name = this.$route.query.record
    this.is_open_dingding = this.$route.query.is_open_dingding
    this.parent_status = this.$route.query.status
    this.subject = this.$route.query.subject
  },
  computed: {
    showOpeStatus () {
      return this.is_open_dingding === '1' || this.is_open_dingding === 1 ? '钉钉审核' : '完成'
    }
  },
  mounted () {
    this.loadData()
    setTimeout(() => {
      this.defaultExpandAllRows = true
    }, 2000)
  },
  methods: {
    isCreate () {
      return this.parent_status === '1' || this.parent_status === 1
    },
    // 加载数据方法 必须为 Promise 对象
    loadData () {
      property_project_item_list(Object.assign(this.queryParam, { project_id: this.$route.params.id }))
        .then(res => {
          console.log(res.data, '我是数据列表@')
          this.$nextTick(() => {
            setTimeout(() => {
              this.defaultExpandAllRows = true
              console.log('我是数据加载完之后的操作')
            }, 4000)
          })
          // this.dataSource = { entries: res.data }
          let index = 1
          this.dataSource = res.data.map(item => {
            item.index = index++
            this.statisticsData(item)
            if (item.children !== undefined && item.children.length > 0) {
              item.children = item.children.map(item_child => {
                item_child.index = index++
                this.statisticsData(item_child)
                return item_child
              })
            }
            return item
          })
        })
    },
    statisticsData (record) {
      if (record.category === 2 || record.category === 3) {
        record.fee_detail.forEach(item => {
          this.statistics.pay.north_gov += item.north_gov
          this.statistics.pay.north_jg += item.north_jg
          this.statistics.pay.north_self += item.north_self
          this.statistics.pay.south_gov += item.south_gov
          this.statistics.pay.south_jg += item.south_jg
          this.statistics.pay.south_self += item.south_self
        })
      }
      if (record.category > 2) {
        record.fee_detail.forEach(item => {
          this.statistics.fee.north_gov += item.north_gov
          this.statistics.fee.north_jg += item.north_jg
          this.statistics.fee.north_self += item.north_self
          this.statistics.fee.south_gov += item.south_gov
          this.statistics.fee.south_jg += item.south_jg
          this.statistics.fee.south_self += item.south_self
        })
      }
      console.log('=============>>>', this.statistics)
    },
    showDrawer () {
      this.visibleDrawer = true
    },
    onClose () {
      this.visibleDrawer = false
    },
    watchList (record) {
      if (record.category === 1) {
        this.corporations = record.attachment
        this.visibleDrawer = true
      } else {
        this.corporations = record.fee_detail
        this.visibleDrawer2 = true
      }
      // this.showDrawer()
    },
    getFileSuffix (filename) {
      console.log(filename, '787800')
      return filename.substring(filename.lastIndexOf('.') + 1).toLowerCase()
    },
    getFileName (filename) {
      let name = filename.substring(filename.lastIndexOf('/') + 1)
      const suffix = filename.substring(filename.lastIndexOf('.')).toLowerCase()
      console.log(name, name.length)
      name = name.length - suffix.length > 10 ? name.substring(0, 5) + '...' + name.substring(8, 10) + suffix : name
      return name
    },
    getFileFullName (filename) {
      return filename.substring(filename.lastIndexOf('/') + 1)
    },
    openPreview (img_url) {
      const fileType = ['pdf', 'jpg', 'jpeg', 'png']
      const suffix = img_url.substring(img_url.lastIndexOf('.') + 1).toLowerCase()
      if (fileType.indexOf(suffix) > -1) {
        this.previewvisible = true
        this.previewurl = img_url
      } else {
        this.$message.error('附件错误，不支持预览')
      }
    },
    onChange (value) {
      console.log(value)
    },
    onSearch (searchText) {
    },
    onSelect (value) {
      this.queryParam.corporation_id = this.corList.filter((item) => (item.name === value) && item)[0].id

      console.log('onSelect', value, this.corList.filter((item) => (item.name === value) && item))
    },
    handleAdd () {
      this.mdl = null
      // this.visible = true
      this.$router.push({ path: '/property_project/' + this.$route.params.id + '/addItem', query: { item_id: -1, subject: this.subject } })
    },
    handlepreCancel () {
      this.previsible = false
    },
    handlepreOk () {
      this.previsible = false
    },
    showAllImages (urls) {
      this.previsible = true
      this.content = urls.map(url => {
        return url.img
      }).toString()
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleEdit (record) {
      // this.$emit('onEdit', record)
      this.$router.push({
        path: '/property_project/' + this.$route.params.id + '/addItem',
        query: { item_id: record.id, subject: this.subject }
      })
    },
    handleStatus (record) {
      this.spinning = true
      console.log(record)
      this.changeDingDingBtnDisabled(record, 1)

      // if (record) {
      //   setTimeout(() => {
      //     if (record.item_id === -1) {
      //       this.dataSource = this.dataSource.filter(item => {
      //         if (item.id === record.id) {
      //           item.dingding_ope = undefined
      //         }
      //         return item
      //       })
      //     } else {
      //       this.dataSource = this.dataSource.filter(item => {
      //         if (item.id === record.item_id) {
      //           item.children = item.children.filter(child_item => {
      //             if (child_item.id === record.id) {
      //               child_item.dingding_ope = undefined
      //             }
      //             return child_item
      //           })
      //         }
      //         return item
      //       })
      //     }
      //     this.spinning = false
      //   }, 4000)
      //   return
      // }

      property_project_item_update(record.id).then(res => {
        console.log(res)
        // this.dataSource = this.dataSource.filter(item => {
        //   if (item.id === record.id) {
        //     if (this.is_open_dingding === '1' || this.is_open_dingding === 1) {
        //       item.status = 2
        //     } else {
        //       item.status = 3
        //     }
        //   }
        //   return item
        // })
        if (res.code === 1000) {
          this.changeDingDingBtnDisabled(record, undefined)
        } else {
          this.changeDingDingBtnDisabled(record, 0)
        }
      }).finally(() => {
        setTimeout(() => {
          this.spinning = false
        }, 500)
      })
    },
    changeDingDingBtnDisabled (record, status) {
      if (record.item_id === -1) {
        this.dataSource = this.dataSource.filter(item => {
          if (item.id === record.id) {
            item.dingding_ope = status

            if (status === undefined) {
              // 修改的父级Record的状态
              if (this.is_open_dingding === '1' || this.is_open_dingding === 1) {
                item.status = 2
              } else {
                item.status = 3
              }
            }
          }
          return item
        })
      } else {
        this.dataSource = this.dataSource.filter(item => {
          if (item.id === record.item_id) {
            item.children = item.children.filter(child_item => {
              if (child_item.id === record.id) {
                child_item.dingding_ope = status
                if (status === undefined) {
                  // 修改的子级Record的状态
                  if (this.is_open_dingding === '1' || this.is_open_dingding === 1) {
                    child_item.status = 2
                  } else {
                    child_item.status = 3
                  }
                }
              }
              return child_item
            })
          }
          return item
        })
      }
    },
    createPayable (record) {
      property_project_item_payable(record.id).then(res => {
        console.log(res)
        this.loadData()
      })
    },
    createPayVoucher (record) {
      property_project_item_pay_voucher(record.id).then(res => {
        console.log(res)
        this.loadData()
      })
    },
    handleDelete (record) {
      property_project_item_delete(record.id).then((res) => {
        console.log(res)
        console.log('删除成功------')
        // this.$refs.table.refresh()
        this.loadData()
      })
    },
    handleOk () {
      const form = this.$refs.createModal.form
      this.confirmLoading = true
      form.validateFields((errors, values) => {
        if (!errors) {
          console.log('values', values)
          // 新增
          if (values.imgs !== undefined && values.imgs.length > 0) {
            values.imgs = values.imgs.split(',')
          }
          values.img = undefined
          values.zone_property_id = parseInt(this.$route.params.id, 10)
          property_project_item_create(values).then(res => {
            this.visible = false
            // 重置表单数据
            form.resetFields()
            // 刷新表格
            // this.$refs.table.refresh()
            this.loadData()
            console.log('创建成功------')
          }).finally(() => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    }
  }
}
</script>

<style scoped>

.a_disable {
  text-decoration: none;
  cursor: default;
  opacity: 0.6;
}

table {
  table-layout: fixed;
  border-left: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

th, td {
  border-right: 1px solid #ddd;
  border-bottom: 0.5px solid #ddd;
  vertical-align: middle;
  text-align: center;
  padding: 12px 0;
  color: #666;
  font-size: 14px;
  width: 120px;
}

th {
  font-weight: normal;
}

.text_right {
  text-align: right;
  padding-right: 10px;
}

.other_bg {
  background-color: #f5f5f5
}

.active {
  font-size: 14px;
  color: #197cff;
}

</style>
